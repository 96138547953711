import { useEffect, useState } from 'react';
import {
  MdCalendarToday,
  MdOutlineInfo,
  MdOutlinePayments,
  MdDeliveryDining,
  MdStore,
} from 'react-icons/md';
import { FaCheckCircle, FaLongArrowAltRight } from 'react-icons/fa';
import { BsClockHistory, BsFillCheckCircleFill } from 'react-icons/bs';
import { formatDate, moeda } from '../../utils/functions';
import { BackButton } from '../ButtonBack';
import Title from '../Title';
import * as S from './styles';
import Loading from '../Loading';
import { useTheme } from 'styled-components';
import Button from '../Button';
import { Spacer } from '../Spacer';
import { PaymentModal } from '../PaymentModal/PaymentModal';
import { getPaymentByOrderId } from 'httpRequests/payment/getPaymentByOrderId';
import { Product } from './Product/Product';
import { defatultStatusState } from './constants';
import { getOrderProduct } from 'httpRequests/order/getOrderProduct';
import { getAddress, getStatus } from './utils';
import { format } from 'date-fns';
import { useConfig } from '../../hooks/config';
import { getMinutesFromTime } from 'utils/handlers/getMinutesFromTime';
import { Box } from '../Box/Box';
import { Text } from '../Text/Text';
import { Order } from 'entities/Order.entity';
import { PixPayment } from 'entities/pixPayment.entity';

type DetailsOrderProps = {
  order: Order;
  onClose: () => void;
  orderId?: string;
};

const DetailsOrder = ({ order, onClose, orderId }: DetailsOrderProps) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [status, setStatus] =
    useState<Partial<typeof defatultStatusState>>(defatultStatusState);
  const { colors } = useTheme();
  const { config } = useConfig();
  const [openPayment, setOpenPayment] = useState(false);
  const [payment, setPayment] = useState<PixPayment | null>(null);
  const canOpenPayment = Boolean(payment);
  const address = getAddress(order);

  const getPayment = async () => {
    const data = await getPaymentByOrderId(order.Id, order.IdEmpresa);
    if (data) setPayment(data);
  };

  const update = async () => {
    setLoading(true);

    const data = await getOrderProduct(order.Id);

    if (data) setProducts(data);

    setLoading(false);
  };

  const updateStatus = () => {
    setStatus(getStatus(order.Status));
  };

  const handlePay = () => {
    setOpenPayment((state) => !state);
  };

  useEffect(() => {
    getPayment();
    update();
  }, []);

  useEffect(() => {
    updateStatus();
  }, [order]);

  return (
    <S.Container>
      <BackButton onClick={onClose} />
      {Boolean(orderId) && (
        <Box width="100%" justifyContent="center" paddingBottom={20} gap={12}>
          <FaCheckCircle size={30} color={colors.success} />
          <Text fontWeight="bold" color={colors.success} variant={'title'}>
            Pedido Recebido
          </Text>
        </Box>
      )}
      <S.ContainerOrder>
        <Title>
          <MdOutlineInfo color={colors.main} size={30} />
          Pedido Nº {order.Codigo}
        </Title>
      </S.ContainerOrder>
      <S.ContainerInfoOrder>
        <S.DataOrder>
          <MdCalendarToday color={colors.main} size={25} />{' '}
          {`${formatDate(order.DataPedido)} às ${order.Hora}`}
        </S.DataOrder>
        <S.PaymentOrder>
          <MdOutlinePayments color={colors.main} size={25} />{' '}
          {order.FormaPagamento}
        </S.PaymentOrder>
        <S.TipeOrder>
          {['EN', 'AG'].includes(order.TipoEntrega) && (
            <>
              <MdDeliveryDining color={colors.main} size={25} />{' '}
              {order.TipoEntrega === 'EN' ? 'Entrega' : 'Entrega Agendada'}
            </>
          )}
          {['RE', 'AGRE'].includes(order.TipoEntrega) && (
            <>
              <MdStore color={colors.main} size={25} />
              {order.TipoEntrega === 'RE' ? 'Retirada' : 'Retirada Agendada'}
            </>
          )}
          {order.TipoEntrega === 'LOCAL' && (
            <>
              <MdStore color={colors.main} size={25} />
              Consumir no local
            </>
          )}
        </S.TipeOrder>
        {order.TipoEntrega?.includes('AG') && (
          <S.TipeOrder>
            <>
              <MdCalendarToday color={colors.main} size={25} />
              {order.TipoEntrega === 'AG' ? 'Entregar em ' : 'Retiradar em '}
              {format(
                new Date(order.DataAgendamento.toString().replace('.000Z', '')),
                'dd/MM/yyyy HH:mm',
              )}
            </>
          </S.TipeOrder>
        )}
      </S.ContainerInfoOrder>

      {Boolean(
        config.MinTimePickUp &&
          config.MaxTimePickUp &&
          config.MinTimeDelivery &&
          config.MaxTimeDelivery,
      ) && (
        <S.StatusOrderContainer>
          <Title>
            Tempo para {['LOCAL'].includes(order.TipoEntrega) && 'conclusão'}
            {['EN', 'AG'].includes(order.TipoEntrega) && 'entrega'}
            {['RE', 'AGRE'].includes(order.TipoEntrega) && 'retirada'}
          </Title>
          <S.DataOrder>
            <BsClockHistory color={colors.main} size={25} />
            Tempo medio para{' '}
            {order.TipoEntrega === 'LOCAL'
              ? `conclusão entre ${getMinutesFromTime(
                  config.MinTimePickUp,
                )} e ${getMinutesFromTime(config.MaxTimePickUp)} minutos`
              : order.TipoEntrega?.includes('RE')
              ? `retirada entre ${getMinutesFromTime(
                  config.MinTimePickUp,
                )} e ${getMinutesFromTime(config.MaxTimePickUp)} minutos`
              : `entrega entre ${getMinutesFromTime(
                  config.MinTimeDelivery,
                )} e ${getMinutesFromTime(config.MaxTimeDelivery)} minutos`}
          </S.DataOrder>
        </S.StatusOrderContainer>
      )}

      <S.StatusOrderContainer>
        <Title>Status do pedido</Title>
        <S.StatusOrder>
          <S.StatusContainer>
            Aguardando
            <S.Status status={Boolean(status.waiting)}>
              <BsClockHistory />
            </S.Status>
          </S.StatusContainer>
          <FaLongArrowAltRight
            color={status.waiting ? colors.success : colors.shapeGray}
            style={{ paddingTop: 10 }}
            size={30}
          />
          <S.StatusContainer>
            Confirmado
            <S.Status status={Boolean(status.confirm)}>
              <BsFillCheckCircleFill />
            </S.Status>
          </S.StatusContainer>
          <FaLongArrowAltRight
            color={status.confirm ? colors.success : colors.shapeGray}
            style={{ paddingTop: 10 }}
            size={30}
          />
          <S.StatusContainer>
            {order.TipoEntrega === 'LOCAL'
              ? 'Conclusão'
              : ['EN', 'AG'].includes(order.TipoEntrega)
              ? 'Entrega'
              : 'Retirar'}
            <S.Status status={Boolean(status.sending)}>
              {['EN', 'AG'].includes(order.TipoEntrega) ? (
                <MdDeliveryDining />
              ) : (
                <MdStore />
              )}
            </S.Status>
          </S.StatusContainer>
        </S.StatusOrder>
      </S.StatusOrderContainer>
      {address && (
        <S.ContainerAddress>
          <Title>Endereço</Title>
          <S.Address>{address}</S.Address>
        </S.ContainerAddress>
      )}
      {loading && <Loading loading={loading} />}
      {!loading && (
        <>
          <S.ContainerProducts>
            <Title>Produtos</Title>
            {products.map((product: any) => (
              <Product
                key={`${product.IdProduto}-${order.Id}`}
                product={product}
                order={order}
              />
            ))}
          </S.ContainerProducts>

          <S.ContainerTotal>
            <Title>Totais</Title>
            <S.Total>
              <S.SubTotalOrder>
                Pedido: {moeda(order.ValorTotal)}
              </S.SubTotalOrder>
              {order.Desconto > 0 && (
                <S.SubTotalOrder>
                  Desconto: {moeda(order.Desconto)}
                </S.SubTotalOrder>
              )}
              <S.TotalDelivery>
                Taxa Entrega: {moeda(order.TaxaEntrega)}
              </S.TotalDelivery>
              <S.TotalOrder>
                Total:{' '}
                {moeda(
                  Number(order.ValorTotal) +
                    Number(order.TaxaEntrega ?? 0) -
                    Number(order.Desconto ?? 0),
                )}{' '}
              </S.TotalOrder>
            </S.Total>
          </S.ContainerTotal>

          <Spacer flex={1} />

          {canOpenPayment && (
            <div style={{ padding: 20 }}>
              <Button style={{ color: '#ffffff' }} onClick={handlePay}>
                Pagar
              </Button>
            </div>
          )}
        </>
      )}

      {openPayment && payment && (
        <PaymentModal payment={payment} onClose={handlePay} />
      )}
    </S.Container>
  );
};

export default DetailsOrder;
