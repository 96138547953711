import QRCode from 'react-qr-code';
import * as S from './styles';
import { BackButton } from '../ButtonBack';
import { money } from 'utils/money';
import { Text } from '../Text/Text';
import pixLogo from '../../assets/logo_pix.png';
import Button from '../Button';
import { PixPayment } from 'entities/pixPayment.entity';
import { useCallback } from 'react';
import { notifications } from 'utils/notifications';

export interface PaymentModalProps {
  payment: PixPayment;
  onClose: () => void;
  amount?: number;
}

const PaymentModal = (props: PaymentModalProps) => {
  const { payment, onClose, amount } = props;
  const { payload } = payment;

  const handleCopyPaste = useCallback(() => {
    navigator.clipboard.writeText(payload);
    notifications.success(`Chave copiada`);
  }, [payload])

  return (
    <S.Container>
      <S.BackButtonWrapper>
        <BackButton onClick={onClose} />
      </S.BackButtonWrapper>

      {amount && (
        <>
          <Text variant="info">Valor a pagar:</Text>
          <Text variant="title">{money(amount)}</Text>
        </>
      )}

      <QRCode value={payload} />

      <S.PixLogo src={pixLogo} />

      <S.DescriptionText>Copie o código Pix abaixo e cole
        para pagar em qualquer aplicativo habilitado
      </S.DescriptionText>

      <S.PanelPixCopyPaste>
        {payload}
      </S.PanelPixCopyPaste>

      <Button onClick={handleCopyPaste} style={{ color: '#FFFFFF' }}>
        Pix Copia e Cola
      </Button>
    </S.Container>
  );
};

export { PaymentModal };
