import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FinishButton,
  FinishChange,
  FinishContainer,
  FinishCoupom,
  FinishCoupomButton,
  FinishFormContainer,
  FinishFormTitle,
  FinishOptionsContainer,
  FinishPayment,
  FinishPaymentAccount,
  FinishPaymentsAccountContainer,
  FinishTaxeContainer,
  FinishTitle,
  FinishTotalContainer,
  FinishTotalsContainer,
  FinishType,
  PaymentAccountBank,
  PaymentAccountData,
  PaymentAccountInfo,
  TaxeData,
  TitleProof,
  TotalData,
  NoDeliveryTypes,
  Container,
} from './styles';
import { mask, unMask } from 'remask';
import { FaCheckCircle, FaShippingFast } from 'react-icons/fa';
import { useConfig } from '../../hooks/config';
import Input from '../../components/Input';
import RadioGroup from '../../components/RadioGroup';
import Dropdown from '../../components/Dropdown';
import api from '../../services/api';
import { notifications } from '../../utils/notifications';
import { useCart } from '../../hooks/useCart';
import { logout, moeda } from '../../utils/functions';
import AddressInsert from './AddressInsert';
import { useSchedules } from '../../hooks/schedules';
import CoupomInsert from './CoupomInsert';
import { useAuth } from '../../hooks/auth';
import { useCompany } from '../../hooks/useCompany';
import { FBPixelTrackEvent } from '../../events/FaceBookPixelEvents';
import { PaymentModal } from '../../components/PaymentModal/PaymentModal';
import Loading from '../../components/Loading';
import { getDeliveryTypes } from './utils/getDeliveryTypes';
import { Spacer } from '../../components/Spacer';
import { Text } from '../../components/Text/Text';
import { FiAlertTriangle } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { useTab } from '../../hooks/tabIndex';
import { DELIVERY_TYPES } from '../../entities/delivery-types';
import { addDays, format } from 'date-fns';
import { getMinMaxTime } from './utils/getCurrentSchedule';
import { daysOfWeek } from '../../utils/constants/daysOfWeekList';
import Button from '../../components/Button';
import { screens } from 'routes/Tabs/screens';
import { onSubmitOrder } from './utils/submitOrder';
import { getAvailableUserGoal } from '../../httpRequests/goal/getAvailableUserGoal';
import { GoalCard } from './components/GoalCard/GoalCard';
import { Box } from '../../components/Box/Box';
import { getPrizeValue } from './utils/getPrizeValue';

const handlePaymentIsMoney = ({ payments, payment }) => {
  const paymentMode = payments.find((pay) => {
    return (
      !Number.isNaN(Number(pay?.Id)) && Number(pay?.Id) === Number(payment)
    );
  });

  const paymentDescription = String(paymentMode?.Descricao);

  return paymentDescription.toLowerCase().includes('dinheiro');
};

const FinishOrder = () => {
  const { finish, handleFinish, setTab } = useTab();
  const { user } = useAuth();
  const { company } = useCompany();
  const IdEmpresa = company?.NrContrato;
  const { config } = useConfig();
  const { schedules, isOpen } = useSchedules();
  const { colors } = useTheme();
  const { total, cart, clearCart } = useCart();

  const [paymentsAccounts, setPaymentsAccounts] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [taxeFreeDeliveryRegion, setTaxeFreeDeliveryRegion] = useState([]);
  const [neighborhoodId, setNeighborhoodId] = useState(null);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [typeDelivery, setTypeDelivery] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [address, setAddress] = useState(null);
  const [number, setNumber] = useState(null);
  const [complement, setComplement] = useState(null);
  const [coupom, setCoupom] = useState(null);
  const [change, setChange] = useState(0);
  const [proof, setProof] = useState(null);
  const [discont, setDiscont] = useState(0);
  const [taxeDelivery, setTaxeDelivery] = useState(0);
  const [taxeDrawal, setTaxeDrawal] = useState(0);
  const [taxeFree, setTaxeFree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [myaddress, setMyAddress] = useState([]);
  const [addressId, setAddressId] = useState(null);
  const [openCoupom, setOpenCoupom] = useState(false);
  const isSubmiting = useRef(false);
  const [payment, setPayment] = useState(null);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [payments, setPayments] = useState([]);
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [needChange, setNeedChange] = useState(true);
  const [goal, setGoal] = useState(null);
  const [useGoal, setUseGoal] = useState(false);

  const minMaxTime = getMinMaxTime(scheduleDate, scheduleTime, schedules);
  const prize = getPrizeValue(goal, total, taxeDelivery);

  const orderTotal = total + taxeDelivery + taxeDrawal;
  const orderLiquidTotal = orderTotal - discont - (useGoal ? prize : 0);
  const showOrderTotal = orderLiquidTotal > 0 ? orderLiquidTotal : 0;

  const isPaymentMoney = handlePaymentIsMoney({
    payments,
    payment: paymentMethod,
  });

  const deliveryTypes = getDeliveryTypes(config, isOpen);

  const resetFinishOrder = () => {
    setPaymentMethod(null);
  };

  const loadUserLocal = useCallback(async () => {
    if (user?.Id) return

    const userLocalStorage = localStorage.getItem('user');

    if (!userLocalStorage) return

    try {
      const userLocal = JSON.parse(userLocalStorage);

      setName(userLocal.name);
      setPhone(userLocal.phone);
      if (userLocal.address) {
        setAddress(userLocal.address.address)
        setNumber(userLocal.address.number)
        setComplement(userLocal.address.complement)
      }
    } catch { return }
  }, [neighborhoods])

  const update = useCallback(async () => {
    await api
      .get(`/bairro/listar/${IdEmpresa}`)
      .then((response) => {
        const neib = response.data.map((obj) => {
          return {
            value: obj.Id,
            label: `${obj.Bairro}`,
            cobraTaxa: obj.CobraTaxa,
            taxaEntrega: obj.TaxaEntrega,
          };
        });
        setNeighborhoods(neib);
      })
      .catch((error) => {
        console.log(
          `Erro ao carregar os bairros. ${error?.response?.data?.message}`,
          error,
        );
      });

    api
      .get(`/forma-pagamento-empresa/listar/${IdEmpresa}`)
      .then((response) => {
        setPayments(response.data);
      })
      .catch((error) => {
        console.log(
          `Erro ao carregar as formas de pagamento. ${error?.response?.data?.message}`,
          error,
        );
      });

    api
      .get(`/conta/listar/${IdEmpresa}`)
      .then((response) => setPaymentsAccounts(response.data))
      .catch((error) => {
        console.log(
          `Erro ao carregar as contas para transferência. ${error?.response?.data?.message}`,
          error,
        );
      });

    api
      .get(`/regiao-bairro/listarTaxas/${IdEmpresa}`)
      .then((response) => {
        setTaxeFreeDeliveryRegion(response.data);
      })
      .catch((error) => {
        console.log(
          `Erro ao carregar as taxas. ${error?.response?.data?.message}`,
          error,
        );
      });
  }, [IdEmpresa]);

  const loadMyAddress = useCallback(async () => {
    if (user?.Id) {
      api
        .get(`/endereco/selecionar/${user?.Id}/${IdEmpresa}`)
        .then((res) => {
          setMyAddress(res.data);
        })
        .catch((error) => {
          console.log(
            error,
            `Erro ao carregar seus endereços. ${error?.response?.data?.message}`,
          );
        });
    } else {
      logout();
    }
  }, [user]);

  const selectMyAddress = useCallback((id) => {
    let taxe = 0,
      free = false;

    const neibhs = neighborhoods.find(
      (x) => x.Id === id && x.CobraTaxa === 'S',
    );
    taxe = neibhs ? neibhs.TaxaEntrega : 0;

    if (config.FreteGratis && config.FreteGratis > 0) {
      if (total >= parseFloat(config.FreteGratis)) {
        taxe = 0;
        free = true;
      }
    } else if (taxeFreeDeliveryRegion.length > 0) {
      taxeFreeDeliveryRegion.forEach((region) => {
        if (region.IdBairro === id && total >= parseFloat(region.TaxaGratis)) {
          taxe = 0;
          free = true;
        }
      });
    }
    const productFreeDelivery = cart.find((x) => x.freteGratis);
    if (productFreeDelivery) {
      taxe = 0;
      free = true;
    }

    if (+config.TaxaEntregaPercentual) {
      let value = taxe;
      taxe = (total * value) / 100;
    }

    setAddressId(id);
    setTaxeDelivery(taxe);
    setTaxeFree(free);
  }, []);

  const verifyAvailability = useCallback(async () => {
    let unavlb = [];
    cart?.forEach(async (item) => {
      await api.get(`/produto/encontrar/${item.idProduto}`).then((response) => {
        if (response.data.Disponivel === 'False') {
          unavlb.push(response.data);
        }
      });
    });
    if (unavlb.length) {
      return true;
    }
    return false;
  }, []);

  const fileSelect = useCallback((event) => {
    setProof(event.target.files[0]);
  }, []);

  const handlePurchaseEvents = () => {
    FBPixelTrackEvent.purchase({
      currency: 'BRL',
      value: total,
      contents: cart.map((item) => ({
        id: item?.idProduto,
        quantity: item?.quantidade,
        name: item?.descricao,
        value: item?.preco,
      })),
    });
  };

  const stopSubmiting = () => {
    isSubmiting.current = false;
    setLoading(false);
  };

  const onClose = (orderId) => {
    clearCart();
    resetFinishOrder();
    handlePurchaseEvents();
    localStorage.removeItem('produtos');
    handleFinish(false);
    setTab(user ? screens.account : screens.consult_order, { orderId });
  };

  const onChangePayment = (paymentData) => {
    if (paymentData) {
      setPayment(paymentData);
      setPaymentOpen(true);
      setLoading(false)
    }
  };

  const handleSubmit = async () => {
    await onSubmitOrder({
      user,
      myaddress,
      typeDelivery,
      addressId,
      scheduleDate,
      scheduleTime,
      payments,
      paymentMethod,
      change,
      IdEmpresa,
      phone,
      address,
      neighborhoodId,
      number,
      complement,
      taxeDelivery,
      taxeDrawal,
      discont,
      total,
      proof,
      cart,
      coupom,
      config,
      isPaymentMoney,
      name,
      needChange,
      goal,
      useGoal,
      onStop: stopSubmiting,
      onClose,
      onChangePayment,
    });
  };

  const handlePayment = (e) => {
    setPaymentMethod(e.target.value);

    const isMoney = handlePaymentIsMoney({ payments, payment: e.target.value });

    if (isMoney) {
      setNeedChange(true);
    }

    if (!isMoney) {
      setChange(0);
    }
  };

  const handleSubmitOrder = async () => {
    if (loading || isSubmiting.current) {
      return;
    }

    isSubmiting.current = true;
    setLoading(true);

    const sellWhenClosed = Boolean(
      config.VendaLojaFechada === 'True' ||
      config.VendaLojaFechada === 1 ||
      config.VendaLojaFechada === true,
    );

    const scheduling =
      config.TipoEntregaCheck?.includes(DELIVERY_TYPES.schedule_delivery) ||
      config.TipoEntregaCheck?.includes(DELIVERY_TYPES.schedule_take_local);

    if (!isOpen && !sellWhenClosed && !scheduling) {
      notifications.error(`Loja fechada`);
      return stopSubmiting();
    }

    const productsUnavailable = await verifyAvailability();

    if (productsUnavailable) {
      handleFinish(false);
      return stopSubmiting();
    }

    handleSubmit();
  };

  const closePayment = () => {
    setPaymentOpen(false);
    onClose();
  };

  const getSchedulesDatesAvailable = (settings) => {
    const schedulesDays = settings?.DiasAgendamento
      ? JSON.parse(settings?.DiasAgendamento)
      : {};

    return schedulesDays;
  };

  const onChangeDate = (event) => {
    const newDate = event.target.value;
    const comparativeDate = new Date(`${newDate} ${scheduleTime}`);
    const schedulesAvailableDates = getSchedulesDatesAvailable(config);

    if (!schedulesAvailableDates[daysOfWeek[comparativeDate.getDay()]]) {
      return notifications.error('Data não disponivel para agendamento');
    }

    setScheduleDate(newDate);
    setScheduleTime('');
  };

  const onChangeTime = (event) => {
    const newTime = event.target.value;
    const newDateTime = new Date(scheduleDate + ' ' + newTime);
    const min = new Date(scheduleDate + ' ' + format(minMaxTime.min, 'HH:mm'));
    const max = new Date(scheduleDate + ' ' + format(minMaxTime.max, 'HH:mm'));

    if (newDateTime < new Date()) {
      return notifications.error(`Hora não pode ser menor que hora atual`);
    }

    if (newDateTime < min) {
      return notifications.error(
        `Hora não pode ser menor que ${format(min, 'HH:mm')}`,
      );
    }
    if (newDateTime > max) {
      return notifications.error(
        `Hora não pode ser maior que ${format(max, 'HH:mm')}`,
      );
    }

    setScheduleTime(newTime);
  };

  const handleNeedChange = () => {
    if (needChange) {
      setChange(null);
    }

    setNeedChange(!needChange);
  };

  const parseChangeValue = (value) => {
    const strValue = String(value * 100);

    const cleanValue = strValue.replaceAll(',', '').replaceAll('.', '');
    const filledValue = String(+cleanValue).padStart(3, '0');

    const newValue = filledValue.slice(0, -2) + ',' + filledValue.slice(-2);

    return newValue;
  };

  const handleChange = (e) => {
    const value = String(e.target.value);

    const cleanValue = value.replaceAll(',', '').replaceAll('.', '');

    setChange(Number(cleanValue) / 100);
  };

  const getUserGoals = async () => {
    const userGoal = await getAvailableUserGoal({
      companyId: IdEmpresa,
      userId: user?.Id,
      phone,
    });
    setGoal(userGoal);
  };

  useEffect(() => {
    if (coupom) {
      let disct = 0;
      if (coupom.TipoDesconto === 1) {
        disct = (parseFloat(coupom.Valor) / 100) * total;
      } else {
        disct = parseFloat(coupom.Valor);
      }
      setDiscont(disct);
    }
  }, [coupom]);

  useEffect(() => {
    update();
    loadMyAddress();
  }, [finish]);

  useEffect(() => {
    if (['RE', 'AGRE'].includes(typeDelivery)) {
      setTaxeDelivery(0);
      setNeighborhoodId(null);
      setAddress(null);
      setNumber(null);
      setComplement(null);
    }
    if (['RE', 'AGRE'].includes(typeDelivery) && config.TaxaRetirada > 0) {
      if (config.TaxaRetiradaPercentual) {
        let value = config.TaxaRetirada;
        let taxe = (total * value) / 100;
        setTaxeDrawal(taxe);
      } else {
        setTaxeDrawal(config.TaxaRetirada);
      }
    } else {
      setTaxeDrawal(0);
    }
  }, [typeDelivery]);

  useEffect(() => {
    loadUserLocal();
  }, [neighborhoods])

  useEffect(() => {
    if (user) {
      getUserGoals();
    }
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <FinishContainer>
          <FinishTitle>
            <FaCheckCircle color="#3CB371" size={50} />
            Finalizar Pedido
          </FinishTitle>
          <FinishFormContainer>
            {!user?.Id && (
              <>
                <FinishFormTitle>Informe seus dados</FinishFormTitle>
                <Input
                  placeholder="Informe seu nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullScreen
                />
                <Spacer y={8} />
                <Input
                  placeholder="Informe seu WhatsApp"
                  value={phone}
                  onChange={(e) =>
                    setPhone(mask(unMask(e.target.value), ['(99)99999-9999']))
                  }
                  fullScreen
                />
              </>
            )}

            <FinishType>
              <FinishFormTitle>Forma de entrega</FinishFormTitle>
              {deliveryTypes.length > 0 ? (
                <RadioGroup
                  value={typeDelivery}
                  options={deliveryTypes}
                  setValue={setTypeDelivery}
                  position="relative"
                />
              ) : (
                <NoDeliveryTypes>
                  <FiAlertTriangle size={24} color={colors.alert} />
                  <Text variant="info">
                    Não há formas de entregas disponíveis no momento
                  </Text>
                </NoDeliveryTypes>
              )}
              {['AG', 'AGRE'].includes(typeDelivery) && (
                <>
                  {typeDelivery === 'AGRE' ? 'Retirar' : 'Receber'} em:
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      gap: 8,
                      marginBottom: 12,
                    }}
                  >
                    <Input
                      type="date"
                      value={scheduleDate}
                      min={
                        minMaxTime.max && minMaxTime.max < new Date()
                          ? format(addDays(new Date(), 1), 'yyyy-MM-dd')
                          : format(new Date(), 'yyyy-MM-dd')
                      }
                      max={format(addDays(new Date(), 7), 'yyyy-MM-dd')}
                      onChange={onChangeDate}
                    />
                    <Input
                      type="time"
                      disabled={!scheduleDate}
                      value={scheduleTime}
                      onChange={onChangeTime}
                    />
                  </div>
                </>
              )}
            </FinishType>

            {['EN', 'AG'].includes(typeDelivery) &&
              (!user?.Id || myaddress?.length === 0) && (
                <AddressInsert
                  address={address}
                  setAddress={setAddress}
                  number={number}
                  setNumber={setNumber}
                  complement={complement}
                  setComplement={setComplement}
                  neighborhoods={neighborhoods}
                  neighborhoodId={neighborhoodId}
                  setNeighborhoodId={setNeighborhoodId}
                  setTaxeDelivery={setTaxeDelivery}
                  setTaxeFree={setTaxeFree}
                  taxeFreeDeliveryRegion={taxeFreeDeliveryRegion}
                />
              )}

            {['EN', 'AG'].includes(typeDelivery) &&
              user?.Id &&
              myaddress?.length > 0 && (
                <>
                  <FinishFormTitle>Endereço para entrega</FinishFormTitle>
                  <Dropdown
                    list={myaddress}
                    objListId="Id"
                    objListLabel="Descricao"
                    name="addressId"
                    value={addressId}
                    onChange={(e) => selectMyAddress(e.target.value)}
                  />
                </>
              )}

            <FinishPayment>
              <FinishFormTitle>Forma de pagamento</FinishFormTitle>
              <Dropdown
                list={payments}
                objListId="Id"
                objListLabel="Descricao"
                name="payment"
                value={paymentMethod}
                onChange={handlePayment}
                fullScreen
              />
            </FinishPayment>
            {isPaymentMoney && (
              <>
                {needChange && (
                  <FinishChange>
                    <FinishFormTitle>Troco para</FinishFormTitle>
                    <Input
                      placeholder="Troco para R$"
                      value={parseChangeValue(change)}
                      onChange={handleChange}
                      fullScreen
                    />
                  </FinishChange>
                )}
                <Spacer y={12} />
                <Button onClick={handleNeedChange}>
                  {needChange ? 'Não preciso de troco' : 'Preciso de troco'}
                </Button>
              </>
            )}

            <Spacer y={8} />

            {!coupom && Boolean(config?.MostrarCupom) && (
              <FinishCoupom>
                <FinishCoupomButton onClick={() => setOpenCoupom(true)}>
                  Inserir cupom de desconto
                </FinishCoupomButton>
              </FinishCoupom>
            )}

            {Boolean(goal) && (
              <GoalCard
                goal={goal}
                setUseGoal={(value) => setUseGoal(value)}
                total={orderTotal}
              />
            )}

            {['21', '19'].includes(paymentMethod) && (
              <>
                <FinishPaymentsAccountContainer>
                  <FinishFormTitle>Contas para transferência</FinishFormTitle>
                  {paymentsAccounts.map((pay) => (
                    <FinishPaymentAccount key={`payments-${pay.Id}`}>
                      <PaymentAccountBank>{pay.Banco}</PaymentAccountBank>
                      {!pay.UsaPix ? (
                        <PaymentAccountInfo>
                          <PaymentAccountData>
                            <b>Ag:</b> {pay.Agencia}
                          </PaymentAccountData>
                          <PaymentAccountData>
                            <b>CC:</b> {pay.Conta}
                          </PaymentAccountData>
                        </PaymentAccountInfo>
                      ) : (
                        <PaymentAccountInfo>
                          <PaymentAccountData>
                            <b>Pix:</b> {pay.Pix}
                          </PaymentAccountData>
                        </PaymentAccountInfo>
                      )}
                      <PaymentAccountData>
                        <b>Favorecido: </b>
                        {pay.Favorecido}
                      </PaymentAccountData>
                    </FinishPaymentAccount>
                  ))}
                </FinishPaymentsAccountContainer>
                <TitleProof>
                  Anexar Comprovante{' '}
                  <b style={{ color: 'red' }}>(Formato de imagem)</b>
                </TitleProof>
                <Input type="file" onChange={fileSelect} />
              </>
            )}

            <FinishTotalsContainer>
              <FinishTaxeContainer>
                <TaxeData>Sub total</TaxeData>
                <TaxeData>{moeda(total)}</TaxeData>
              </FinishTaxeContainer>
              {taxeDrawal > 0 && (
                <FinishTaxeContainer>
                  <TaxeData>Taxa de retirada:</TaxeData>
                  <TaxeData>{moeda(taxeDrawal)}</TaxeData>
                </FinishTaxeContainer>
              )}
              {useGoal && (
                <Box justifyContent="space-between">
                  <Text variant="info">Resgate Promoção:</Text>
                  <Text variant="info">
                    -{' '}
                    {moeda(
                      Number(prize) > Number(orderTotal) ? orderTotal : prize,
                    )}
                  </Text>
                </Box>
              )}
              {!taxeFree ? (
                <FinishTaxeContainer>
                  <TaxeData>Taxa de entrega:</TaxeData>
                  <TaxeData>{moeda(taxeDelivery)}</TaxeData>
                </FinishTaxeContainer>
              ) : (
                <FinishTaxeContainer>
                  <TaxeData successColor>
                    <FaShippingFast size={13} color="#008000" /> Entrega grátis
                  </TaxeData>
                </FinishTaxeContainer>
              )}
              {coupom && (
                <FinishTaxeContainer>
                  <TaxeData successColor>Desconto</TaxeData>
                  <TaxeData successColor>{moeda(discont)}</TaxeData>
                </FinishTaxeContainer>
              )}
              <FinishTotalContainer>
                <TotalData>Total</TotalData>
                <TotalData>{moeda(showOrderTotal)}</TotalData>
              </FinishTotalContainer>
            </FinishTotalsContainer>
            <FinishOptionsContainer>
              <FinishButton onClick={() => handleFinish(false)}>
                Não Enviar
              </FinishButton>
              <FinishButton
                mainColor
                onClick={handleSubmitOrder}
                disabled={loading}
              >
                Enviar Pedido
              </FinishButton>
            </FinishOptionsContainer>
          </FinishFormContainer>
        </FinishContainer>
      </Container>
      {openCoupom && (
        <CoupomInsert
          show={openCoupom}
          close={() => setOpenCoupom(false)}
          setCoupom={setCoupom}
        />
      )}
      {paymentOpen && (
        <PaymentModal payment={payment} onClose={closePayment} amount={showOrderTotal} />
      )}
    </>
  );
};

export default FinishOrder;
